import React, { useEffect, useContext, useRef, useState } from 'react';
import {
} from '@ant-design/icons';
import { Breadcrumb, Table, Divider, Form, Input } from 'antd';

import MLayout from '../Layout/MLayout';

import './batchData.css'

import 'leancloud-storage';

import axios from 'axios';

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
        console.log('开始编辑: record');
        console.log(record);
        
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        console.log('结束编辑');
        console.log(values);
        console.log(record);
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('修改表格文字失败:', errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: false,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
};

const handleSave = (row) => {
    
};


const AV = require("leancloud-storage")


function BatchData() {
    
  const [batchDatas, setBatchDatas] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        console.log('batchData useEffect')
        // AV.init({ // 测试
        //     appId: "I5tgGDDyQcTy9vrqCqBosUBw-gzGzoHsz",  
        //     appKey: "zf9DK8ryNyvfnjvQlDc6JFvo", 
        //     serverURL: "https://test-api.onebloc.cn", 
        //     masterKey:"UmnI0pJZJU4HquzXWd0Rgwq6",
        //     useMasterKey: true,
        // })
        AV.init({ // 正式
            appId: "4CNxWZ3qzIZrqDnNRszxIJhY-gzGzoHsz",  
            appKey: "vmVtdLbg08QyTKmnXGUhS2OS", 
            serverURL: "https://api.onebloc.cn",
            masterKey:"owDPUyiK2VmEQd9x1AYvQtnz",
            useMasterKey: true,
        })
        AV.debug.enable()
        loadBatchesDatas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: '活动名称',
            dataIndex: 'batchName',
            key: 'batchName',
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: 'SKU名称',
            dataIndex: 'skuName',
            key: 'skuName',
        },
        {
            title: '所属渠道',
            dataIndex: 'channelName',
            key: 'channelName',
        },
        {
            title: '发行数量',
            dataIndex: 'totalCount',
            key: 'totalCount',
        },
        {
            title: '兑换次数',
            key: 'activeCount',
            dataIndex: 'activeCount',
        },
        {
            title: '兑换率',
            key: 'activeRate',
            dataIndex: 'activeRate',
        },
        {
            title: '订单完拍数',
            key: 'completeOrderCount',
            dataIndex: 'completeOrderCount',
        },
        {
            title: '订单完拍率',
            key: 'completeOrderRate',
            dataIndex: 'completeOrderRate',
        },
        {
            title: '平均交付天数',
            key: 'completeDuration',
            dataIndex: 'completeDuration',
        },
        {
            title: '发行时间',
            key: 'createdAt',
            dataIndex: 'createdAt',
        },
    ];

    const resultColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
    });

    
    const loadBatchesDatas = () => {

        setTableLoading(true)

        axios({
            method: 'get',
            url: 'https://server.onebloc.cn/outputDatasTwo',
            responseType: 'json'
        })
            .then(res => {
                console.log(`请求数据成功${res}`);
                console.log(`请求数据成功 code ${res.data.code}`);
                console.log(`请求数据成功 data ${res.data.data}`);
                setTableLoading(false)
                setBatchDatas(res.data.data)
            })
            .catch(err => {
                console.log(`请求数据失败${err.toString()}`);
                setTableLoading(false)
            })

        
    }

    // const [messageApi, contextHolder] = message.useMessage();
    // const success = (string) => {
    //     messageApi.open({
    //     type: 'success',
    //     content: string,
    //     });
    // };
    // const error = (string) => {
    //     messageApi.open({
    //     type: 'error',
    //     content: string,
    //     });
    // };


    return (
        <MLayout selectedKey={'BatchData'} openKey={'3'}>
            
            <Breadcrumb
                style={{
                    margin: '10px 16px',
                    fontWeight: 600,
                    color: '#ffffff',
                    fontSize: '18px',
                }}
            >
                <Breadcrumb.Item> 活动数据 </Breadcrumb.Item>
            </Breadcrumb>
            <Divider 
                style={{
                    margin: '10px 0',
                }} 
            />
            
            <div 
                className='main'
                style={{

                }}
            >
                
                <Table 
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={resultColumns}
                    dataSource={batchDatas}
                    loading={tableLoading}
                />
                
            </div>

        </MLayout>

    )


}

export default BatchData;