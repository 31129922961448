import React, { useEffect, useState } from 'react';
import {
  FileOutlined,
  PieChartOutlined,
  UserOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';

import { useNavigate } from "react-router-dom";

import { Layout, Menu, theme, Image } from 'antd';

import 'leancloud-storage';

const { Content, Sider } = Layout;



function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('运营管理', '1', <FileOutlined />, [
    getItem('渠道管理', 'Channel'),
    getItem('活动管理', 'ChannelBatch'),
    getItem('订单导出', 'Output'),
  ]),
  getItem('用户管理', '2', <UserOutlined />),
  getItem('数据统计', '3', <PieChartOutlined />, [
    getItem('活动数据', 'BatchData'),
  ]),
  getItem('订单管理', '4', <OrderedListOutlined />),
];

const AV = require("leancloud-storage")

function MLayout(props) {

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey] = useState(props.selectedKey)
  const [openKey] = useState(props.openKey)


  useEffect(() => {
    console.log('MLayout useEffect')
    // AV.init({ // 测试
        //     appId: "I5tgGDDyQcTy9vrqCqBosUBw-gzGzoHsz",  
        //     appKey: "zf9DK8ryNyvfnjvQlDc6JFvo", 
        //     serverURL: "https://test-api.onebloc.cn", 
        //     masterKey:"UmnI0pJZJU4HquzXWd0Rgwq6",
        //     useMasterKey: true,
        // })
        AV.init({ // 正式
          appId: "4CNxWZ3qzIZrqDnNRszxIJhY-gzGzoHsz",  
          appKey: "vmVtdLbg08QyTKmnXGUhS2OS", 
          serverURL: "https://api.onebloc.cn",
          masterKey:"owDPUyiK2VmEQd9x1AYvQtnz",
          useMasterKey: true,
      })
      AV.debug.enable()
      checkLogin()
      //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkLogin = () => {
    const username = localStorage.getItem("moment_username")
    const password = localStorage.getItem("moment_password") 
    if (username && password) {
      const query = new AV.Query("AdminAccount");
      query.equalTo("username", username)
      query.equalTo("password", password)
      query.find().then((users) => {
          console.log(`登录结果：${users}`);
          if (users.length > 0) {
            console.log(`登录成功：${users}`);
          } else {
            console.log(`登录失败：${users}`);
            navigate('/')
          }
      });
    } else {
      navigate('/')
    }
  }

const navigate = useNavigate();

const jumpToPage = (key) => {
  console.log(`选择了key ${key}`)
    if (key === 'Channel') {
      navigate('/Channel')
    } else if (key === 'ChannelBatch') {
      navigate('/Batch')
    } else if (key === 'Output') {
      navigate('/Output')
      // window.open('/订单导出工具.html')
    } else if (key === 'BatchData') {
      navigate('/BatchData')
    }
}

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider 
      collapsed={collapsed} 
      onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu 
        theme="dark" 
        defaultSelectedKeys={[selectedKey]} 
        defaultOpenKeys={[openKey]}
        mode="inline" 
        items={items} 
        onSelect={(info) => {
          if (info.selectedKeys.length > 0) {
            const keyString = info.selectedKeys[info.selectedKeys.length-1]
            console.log(`选择菜单栏: ${keyString}`)
            jumpToPage(keyString)
          }
        }}
        />
        <div className="image-container" style={{
            position: 'fixed',
            bottom: 0,
          }}>
          <Image preview={false} width={200} height={112} src="https://file.onebloc.cn/K6KTYNWA5VwF1BNmyuiNDVVXYPGbiGu8/WechatIMG9706.jpg"/>
        </div>
      </Sider>
      <Layout>
        
        <Content
          style={{
            margin: '0 0px',
            background: colorBgContainer,
          }}
        >

          {props.children}
          
        </Content>
        
      </Layout>

    </Layout>
  );
}

export default MLayout;