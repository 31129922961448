import React, { useEffect, useContext, useRef, useState } from 'react';
import {
} from '@ant-design/icons';
import { Breadcrumb, Button, Space, Table, Divider, Modal, Form, Input, message, Select } from 'antd';

import MLayout from '../Layout/MLayout';

import './batch.css'

import 'leancloud-storage';

import JSZip from 'jszip';
import { saveAs } from 'file-saver'
import axios from 'axios';

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
        console.log('开始编辑: record');
        console.log(record);
        
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        console.log('结束编辑');
        console.log(values);
        console.log(record);
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('修改表格文字失败:', errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: false,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
};


const AV = require("leancloud-storage")

var currentChannels = []

var currentSKUs = []

const fetchChannelOptions = () => {
    const query = new AV.Query("M_Channel");
    query.find().then((channels) => {
        console.log(`渠道获取成功：${channels}`);
        currentChannels = channels
    });
};

const fetchSKUOptions = () => {
    const query = new AV.Query("PricePackage");
    query.find().then((skus) => {
        console.log(`SKU获取成功：${skus}`);
        currentSKUs = skus
    });
};

const currentChannelsToChannelOptions = () => {
    return currentChannels.map((channel, index) => {
        const dict = channel.toJSON()
        return {
            label: dict.name,
            value: dict.objectId,
        }
    });
};

const currentSKUsToSKUOptions = () => {
    return currentSKUs.map((sku, index) => {
        const dict = sku.toJSON()
        return {
            label: dict.skuName,
            value: dict.objectId,
        }
    });
};

const qrCodeParam = {}

function Batch() {

  const [openCreareQRCodeModal, setOpenCreareQRCodeModal] = useState(false);
  const [confirmQRCodeLoading, setConfirmQRCodeLoading] = useState(false);

  const [openCreareModal, setOpenCreareModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [batchDatas, setBatchDatas] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        console.log('channels useEffect')
        // AV.init({ // 测试
        //     appId: "I5tgGDDyQcTy9vrqCqBosUBw-gzGzoHsz",  
        //     appKey: "zf9DK8ryNyvfnjvQlDc6JFvo", 
        //     serverURL: "https://test-api.onebloc.cn", 
        //     masterKey:"UmnI0pJZJU4HquzXWd0Rgwq6",
        //     useMasterKey: true,
        // })
        AV.init({ // 正式
            appId: "4CNxWZ3qzIZrqDnNRszxIJhY-gzGzoHsz",  
            appKey: "vmVtdLbg08QyTKmnXGUhS2OS", 
            serverURL: "https://api.onebloc.cn",
            masterKey:"owDPUyiK2VmEQd9x1AYvQtnz",
            useMasterKey: true,
        })
        AV.debug.enable()
        fetchChannelOptions()
        fetchSKUOptions()
        loadBatchesDatas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: '活动名称',
            dataIndex: 'batchName',
            key: 'batchName',
            editable: true,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            editable: true,
        },
        {
            title: 'SKU名称',
            dataIndex: 'skuName',
            key: 'skuName',
        },
        {
            title: '所属渠道',
            dataIndex: 'channelName',
            key: 'channelName',
        },
        {
            title: '发行数量',
            dataIndex: 'totalCount',
            key: 'totalCount',
        },
        {
            title: '首次兑换数',
            key: 'firstActiveCount',
            dataIndex: 'firstActiveCount',
        },
        {
            title: '首次兑换率',
            key: 'firstActiveRate',
            dataIndex: 'firstActiveRate',
        },
        {
            title: '完拍用户数',
            key: 'completeOrderUserCount',
            dataIndex: 'completeOrderUserCount',
        },
        {
            title: '完拍率',
            key: 'completeOrderUserRate',
            dataIndex: 'completeOrderUserRate',
        },
        {
            title: '复购用户数',
            key: 'rebuyUserCount',
            dataIndex: 'rebuyUserCount',
        },
        {
            title: '复购/完拍用户数',
            key: 'rebuyUserRate',
            dataIndex: 'rebuyUserRate',
        },
        {
            title: '发行时间',
            key: 'createdAt',
            dataIndex: 'createdAt',
        },
        {
            title: '导出二维码',
            key: 'output',
            dataIndex: 'output',
            render: (_, record) => {
                if (record.batchId.length > 0 && record.batchId !== "6624e6533cfdad0f9257d395") {
                    return (<Space style={{ marginBottom: '10px' }}  >
                                <Button onClick={(e) => { 
                                    showCreateQRCodeModal({batchId: record.batchId, batchName: record.batchName, channelId: record.channelId, channelName: record.channelName, skuId: record.skuId})
                                }}>
                                    导出二维码
                                </Button>
                            </Space>)
                
                } else {
                    return(<div></div>)
                }
            },
        },
    ];

    
    const loadBatchesDatas = () => {

        setTableLoading(true)

        axios({
            method: 'get',
            url: 'https://server.onebloc.cn/outputDatasOne',
            responseType: 'json'
        })
            .then(res => {
                console.log(`请求数据成功${res}`);
                console.log(`请求数据成功 code ${res.data.code}`);
                console.log(`请求数据成功 data ${res.data.data}`);
                setTableLoading(false)
                setBatchDatas(res.data.data)
            })
            .catch(err => {
                console.log(`请求数据失败${err.toString()}`);
                setTableLoading(false)
            })

        
    }

    

    const [messageApi, contextHolder] = message.useMessage();
    const success = (string) => {
        messageApi.open({
        type: 'success',
        content: string,
        });
    };
    const error = (string) => {
        messageApi.open({
        type: 'error',
        content: string,
        });
    };


    const [qrCodeform] = Form.useForm();

    const showCreateQRCodeModal = (param) => {

        if (!param.batchId || param.batchId.length <= 0) {
            error(`参数错误，请重新导出`)
            return
        }
        if (!param.channelId || param.channelId.length <= 0) {
            error(`参数错误，请重新导出`)
            return
        }
        
        qrCodeParam.batchId = param.batchId
        qrCodeParam.batchName = param.batchName
        qrCodeParam.channelId = param.channelId
        qrCodeParam.channelName = param.channelName
        qrCodeParam.skuId = param.skuId
        setOpenCreareQRCodeModal(true);
        console.log('显示活动二维码下载弹窗')
        console.log(param)
        console.log(qrCodeParam)
    };

    const cancelCreateQRCodeModel = () => {
        setOpenCreareQRCodeModal(false);
    };

    const handleCreateQRCodeModelOK = () => {

        const formvalues = qrCodeform.getFieldsValue(true)

        console.log(`活动导出formvalues`);
        console.log(formvalues);
    
        if (!formvalues.outputCount || formvalues.outputCount.length <= 0 || parseInt(formvalues.outputCount) <= 0) {
            error(`数量输入异常，请检查`)
            return
        }

        if (!qrCodeParam.batchId || qrCodeParam.batchId.length <= 0 ||
            !qrCodeParam.channelId || qrCodeParam.channelId.length <= 0 ||
            !qrCodeParam.skuId || qrCodeParam.skuId.length <= 0) {
            console.log(`活动导出参数异常`);
            console.log(qrCodeParam);
            error(`数量输入异常，请检查`)
            return
        }

        setConfirmQRCodeLoading(true)

        var index = 0;
        const codes = [];
        while(index < formvalues.outputCount) {
            index = index + 1
            const userCode = new AV.Object("UserCodes");
            userCode.set("batchId", qrCodeParam.batchId)
            userCode.set("batchName", qrCodeParam.batchName)
            userCode.set("channelId", qrCodeParam.channelId)
            userCode.set("use", false)
            userCode.set("channelName", qrCodeParam.channelName)
            userCode.set("skuId", qrCodeParam.skuId)
            codes.push(userCode);
        }
        AV.Object.saveAll(codes).then(
            function (savedObjects) {
                // 成功保存所有对象时进入此 resolve 函数，savedObjects 是包含所有 AV.Object 的数组
                console.log(`${formvalues.outputCount}个二维码保存成功。开始导出}`);
                createQRcode(savedObjects)
            },
            function (error) {
                console.log(`二维码保存失败：${error}`);
                error(error.message)
                setConfirmQRCodeLoading(false)
            }
        );
    };

    const createQRcode = (codes) => {
        var zip = new JSZip()
        var allFolder = zip.folder("二维码")
        dowloadQRCodeImage(zip, allFolder, codes)
    };

    const dowloadQRCodeImage = (zip, ALLFolder, codes) => {
        const promises = []
        codes.forEach((item, index) => {
            var dict = item.toJSON()
            const ustr = dict['objectId']
            // const urlString = `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=https://test-server.onebloc.cn/welcomeToMoment?${ustr}`
            const urlString = `http://gzlong7.com/grvt_mirror/chart?cht=qr&chs=400x400&choe=UTF-8&chld=L|0&chl=https://server.onebloc.cn/welcomeToMoment?${ustr}`
            console.log(`qrcode_url ${urlString}`);
            const promise = getFile(urlString).then(data => {
                const indexString = `${index + 1}`.padStart(5, '0')
                ALLFolder.file(`${indexString}.png`, data, { binary: true }) // 逐个添加文件
            })
            promises.push(promise)
            
        });

        Promise.all(promises)
        .then(() => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, `二维码.zip`);
                console.log(`二维码导出成功`);
                setConfirmQRCodeLoading(false)
                cancelCreateQRCodeModel()
                success("二维码导出成功")
                loadBatchesDatas()
            });
        })
        .catch(res => {
            console.log(`二维码导出失败：${error}`);
            error(error.message)
            setConfirmQRCodeLoading(false)
        })
    };

    const getFile = (url) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                responseType: 'blob'
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.toString())
                })
        })
    };



    const [form] = Form.useForm();

    const showCreateModal = () => {
        setOpenCreareModal(true);
    };

    const cancelCreateModel = () => {
        setOpenCreareModal(false);
    };

    const handleCreateModelOK = () => {
        const formvalues = form.getFieldsValue(true)
        console.log(`点击了OK select：`)
        console.log(formvalues.channelId)

        if (!formvalues.name || formvalues.name.length <= 0) {
            error(`请输入活动名称`)
            return
        }

        if (!formvalues.channelId || formvalues.channelId.length <= 0) {
            error(`请选择关联渠道`)
            return
        }

        if (!formvalues.skuId || formvalues.skuId.length <= 0) {
            error(`请选择关联SKU`)
            return
        }

        var channelName = ""
        const filteredItems = currentChannels.filter(channel => channel.get(`objectId`) === formvalues.channelId);
        if (filteredItems && filteredItems.length > 0) {
            channelName = filteredItems[0].get("name")
        }
        
        if (!channelName || channelName.length <= 0) {
            error(`关联渠道无效，请重新选择`)
            return
        }

        setConfirmLoading(true)

        const channel = new AV.Object("M_ChannelBatch");
        channel.set("name", formvalues.name)
        channel.set("skuId", formvalues.skuId)
        channel.set("channelId", formvalues.channelId)
        channel.set("channelName", channelName)
        channel.set("remark", formvalues.remark ? formvalues.remark : '')
        channel.save().then(
            (todo) => {
                console.log(`活动保存成功。objectId：${todo.id}`);
                setConfirmLoading(false)
                cancelCreateModel()
                success("活动创建成功")
                loadBatchesDatas()
            },
            (error) => {
                console.log(`活动保存失败：${error}`);
                error(error.message)
                setConfirmLoading(false)
            }
        )
    };


    const resultColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
    });
    
    const handleSave = (row) => {
        console.log(`保存活动`);
        console.log(row);
        const newData = [...batchDatas];
        const index = newData.findIndex((item) => row.batchId === item.batchId);
        const item = newData[index];
        if (row.remark === item.remark) {

        } else {
            item.remark = row.remark
            setBatchDatas(newData);
            const todo = AV.Object.createWithoutData("M_ChannelBatch", row.batchId)
            todo.set("remark", row.remark);
            todo.save();
        }

        if (row.batchName === item.batchName) {

        } else {
            item.batchName = row.batchName
            setBatchDatas(newData);
            const todo = AV.Object.createWithoutData("M_ChannelBatch", row.batchId)
            todo.set("name", row.batchName);
            todo.save();
        }
        
    };


    return (
        <MLayout selectedKey={'ChannelBatch'} openKey={'1'}>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: '10px 16px',
                    fontWeight: 600,
                    color: '#ffffff',
                    fontSize: '18px',
                }}
            >
                <Breadcrumb.Item> 活动管理 </Breadcrumb.Item>
            </Breadcrumb>
            <Divider 
                style={{
                    margin: '10px 0',
                }} 
            />
            
            <div 
                className='main'
                style={{

                }}
            >
                
                <Space style={{ marginBottom: '10px' }}  >
                    <Button onClick={(e) => { showCreateModal() }}>添加活动</Button>
                </Space>
                
                <Table 
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={resultColumns}
                    dataSource={batchDatas}
                    loading={tableLoading}
                />

                <Modal
                    title="导出活动二维码"
                    okText="确定"
                    cancelText="取消"
                    maskClosable={false}
                    destroyOnClose={true}
                    open={openCreareQRCodeModal}
                    onOk={handleCreateQRCodeModelOK}
                    confirmLoading={confirmQRCodeLoading}
                    onCancel={cancelCreateQRCodeModel}
                >
                    <Form
                        preserve={false}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        layout='horizontal'
                        form={qrCodeform}
                        initialValues={{
                            layout: 'horizontal',
                        }}
                        style={{
                            marginTop: 30,
                            maxWidth: 700,
                        }}
                    >
                        
                        <Form.Item 
                            label="导出数量"
                            name="outputCount"
                        >
                            <Input placeholder="输入需要导出的数量" />
                        </Form.Item>
                        
                    </Form>

                </Modal>


                <Modal
                    title="创建渠道"
                    okText="确定"
                    cancelText="取消"
                    destroyOnClose={true}
                    open={openCreareModal}
                    onOk={handleCreateModelOK}
                    confirmLoading={confirmLoading}
                    onCancel={cancelCreateModel}
                >
                    <Form
                        preserve={false}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        layout='horizontal'
                        form={form}
                        initialValues={{
                            layout: 'horizontal',
                        }}
                        style={{
                            marginTop: 30,
                            maxWidth: 700,
                        }}
                    >
                        <Form.Item 
                            label="活动名称"
                            name="name"
                        >
                            <Input placeholder="输入活动名称" />
                        </Form.Item>

                        <Form.Item name="channelId" label="关联渠道" rules={[{ required: true }]}>
                            <Select
                                placeholder="请选择活动关联的渠道"
                                allowClear
                                options={currentChannelsToChannelOptions()}
                            >
                            </Select>
                        </Form.Item>

                        <Form.Item name="skuId" label="SKU选择" rules={[{ required: false }]}>
                            <Select
                                placeholder="请选择导出二维码的SKU"
                                allowClear
                                options={currentSKUsToSKUOptions()}
                            >
                            </Select>
                        </Form.Item>

                        <Form.Item 
                            label="备注"
                            name="remark"
                        > 
                            <Input placeholder="输入渠道的备注描述" />
                        </Form.Item>
                        
                    </Form>

                </Modal>
                
            </div>

        </MLayout>

    )


}

export default Batch;