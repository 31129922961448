import React, { useEffect } from 'react';
import { Button, Form, Input, message, Image} from 'antd';

import { useNavigate } from "react-router-dom";

import 'leancloud-storage';


const AV = require("leancloud-storage")

function LoginPage(props) {

    const navigate = useNavigate();

    useEffect(() => {
        console.log('loginPage useEffect')
        // AV.init({ // 测试
        //     appId: "I5tgGDDyQcTy9vrqCqBosUBw-gzGzoHsz",  
        //     appKey: "zf9DK8ryNyvfnjvQlDc6JFvo", 
        //     serverURL: "https://test-api.onebloc.cn", 
        //     masterKey:"UmnI0pJZJU4HquzXWd0Rgwq6",
        //     useMasterKey: true,
        // })
        AV.init({ // 正式
            appId: "4CNxWZ3qzIZrqDnNRszxIJhY-gzGzoHsz",  
            appKey: "vmVtdLbg08QyTKmnXGUhS2OS", 
            serverURL: "https://api.onebloc.cn",
            masterKey:"owDPUyiK2VmEQd9x1AYvQtnz",
            useMasterKey: true,
        })
        AV.debug.enable()
    }, [])

    const onFinish = (values) => {
        console.log('Success:', values);

        const username = values.username
        const password = values.password
        
        const query = new AV.Query("AdminAccount");
        query.equalTo("username", username)
        query.equalTo("password", password)
        query.find().then((users) => {
            console.log(`登录结果：${users}`);
            if (users.length > 0) {
                console.log(`登录成功：${users}`);
                success("登录成功!")
                localStorage.setItem("moment_username", username)
                localStorage.setItem("moment_password", password)
                navigate('/Channel')
            } else {
                console.log(`登录失败：${users}`);
                error("登录失败! 请输入正确的账号和密码")
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // error("请输入账号和密码")
    };

    const [messageApi, contextHolder] = message.useMessage();
    const success = (string) => {
        messageApi.open({
        type: 'success',
        content: string,
        });
    };
    const error = (string) => {
        messageApi.open({
        type: 'error',
        content: string,
        });
    };

    return (
        <div >
            {contextHolder}
            <div className="login-container" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>
                <Image preview={false} width={450} height={269} src="https://file.onebloc.cn/nPeRu6GTDnnNGDuG4eQeOKlxxlDurIYa/WechatIMG9704.jpg"/>
                <Form
                    name="basic"
                    // labelCol={{
                    // span: 8,
                    // }}
                    // wrapperCol={{
                    // span: 16,
                    // }}
                    style={{
                    position: 'relative',
                    width: 400,
                    margin: '150px',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                    label="请先登录"
                    name="username"
                    >
                    </Form.Item>

                    <Form.Item
                    label="账号"
                    name="username"
                    rules={[
                        {
                        required: true,
                        message: '请输入账号!',
                        },
                    ]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                    label="密码"
                    name="password"
                    rules={[
                        {
                        required: true,
                        message: '请输入密码!',
                        },
                    ]}
                    >
                    <Input.Password />
                    </Form.Item>

                    <Form.Item
                    wrapperCol={{
                        // offset: 8,
                        span: 16,
                    }}
                    >
                    <Button type="primary" htmlType="submit">
                        登录
                    </Button>
                    </Form.Item>
                </Form>
                
            </div>

        </div>
    );

}
export default LoginPage;