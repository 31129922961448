import React, { useEffect, useContext, useRef, useState } from 'react';
import {
} from '@ant-design/icons';
import { Breadcrumb, Button, Space, Table, Divider, Modal, Form, Input, message } from 'antd';

import MLayout from '../Layout/MLayout';

import './channel.css'

import 'leancloud-storage';

const AV = require("leancloud-storage")

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
        console.log('开始编辑: record');
        console.log(record);
        
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        console.log('结束编辑');
        console.log(values);
        console.log(record);
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('修改表格文字失败:', errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: false,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
};

function Channel() {

  const [openCreareModal, setOpenCreareModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [channelDatas, setChannelDatas] = useState([]);

useEffect(() => {
    console.log('channels useEffect')
    // AV.init({ // 测试
    //     appId: "I5tgGDDyQcTy9vrqCqBosUBw-gzGzoHsz",  
    //     appKey: "zf9DK8ryNyvfnjvQlDc6JFvo", 
    //     serverURL: "https://test-api.onebloc.cn", 
    //     masterKey:"UmnI0pJZJU4HquzXWd0Rgwq6",
    //     useMasterKey: true,
    // })
    AV.init({ // 正式
        appId: "4CNxWZ3qzIZrqDnNRszxIJhY-gzGzoHsz",  
        appKey: "vmVtdLbg08QyTKmnXGUhS2OS", 
        serverURL: "https://api.onebloc.cn",
        masterKey:"owDPUyiK2VmEQd9x1AYvQtnz",
        useMasterKey: true,
    })
    AV.debug.enable()
    loadChannelDatas()
     // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

const columns = [
    {
      title: '渠道名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      editable: true,
    },
    {
      title: '累计发行',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: '累计激活',
      key: 'useCount',
      dataIndex: 'useCount',
    },
    {
      title: '激活率',
      key: 'useRate',
      dataIndex: 'useRate',
    },
    {
        title: '创建时间',
        key: 'createdAt',
        dataIndex: 'createdAt',
    },
];

const fetchChannelInfo = (channel) => {
    return new Promise((resolve, reject) => {

        console.log(`开始获取渠道${channel}的码信息`);
        console.log(channel);

        const query = new AV.Query("UserCodes");
        query.equalTo("channelName", channel.get("name"))

        console.log(`开始获取UserCodes码信息`);
        query.count({useMasterKey: true}).then((count) => {
            console.log(`${channel.get("name")}渠道总共有 ${count}个激活码`);
            channel.set("total", count)
            query.equalTo("use", true)
            query.count({useMasterKey: true}).then((useCount) => {
                console.log(`${channel.get("name")}渠道总共有 ${useCount}个已经激活`);
                channel.set("useCount", useCount)
                resolve(channel)
            }).catch(err => {
                console.log(`${channel.get("name")}渠道获取userCount失败： ${err.toString()}`);
                reject(err.toString())
            })
        }).catch(err => {
            console.log(`${channel.get("name")}渠道获取total失败： ${err.toString()}`);
            reject(err.toString())
        })
    })
}


const loadChannelDatas = () => {

    const query = new AV.Query("M_Channel");
    query.find().then((channels) => {
        console.log(`渠道获取成功：${channels}`);
        
        fetchChannelGroupInfos(channels, 0, 6, [])
        
    });
}

const fetchChannelGroupInfos = (channels, index, subGroupLength, results) => {

    const currentIndex = index + subGroupLength <  channels.length ?  index + subGroupLength : channels.length

    var datas = channels.slice(index, currentIndex)

    const promises = [] 

    datas.forEach((item, index) => {
        const promise = fetchChannelInfo(item)
        promises.push(promise)
    });

    Promise.all(promises).then(() => {
        const dicts = datas.map((item, index) => {
            var dict = item.toJSON()
            console.log(`构建后的渠道数据：`);
            console.log(dict);
            dict.createdAt = getDateSTring(dict.createdAt)
            if (dict.total > 0 && dict.useCount > 0) {
                var rate = dict.useCount / dict.total * 100
                dict.useRate = `${ rate.toFixed(1) }%`
            } else {
                dict.useRate = '0%'
            }
            return dict
        });

        var resultSum = results.concat(dicts)

        if (currentIndex >= channels.length) {
            console.log(`完成第全部获取渠道的码信息`);
            setChannelDatas(resultSum)
        } else {
            console.log(`完成第${index}获取渠道的码信息`);
            fetchChannelGroupInfos(channels, currentIndex, subGroupLength, resultSum)
        }

    }).catch(err => {
        alert(`数据查询失败，${err.toString()}`);
    })
}

//输入UTC时间字符串，输出YYYY-MM-dd HH:mm:ss
const getDateSTring = (dateString) => {
    let timer = new Date(dateString)
    let year = timer.getFullYear()
    let month = timer.getMonth() + 1
    let day = timer.getDate()
    let hour = timer.getHours()
    let minutes = timer.getMinutes()
    let seconds = timer.getSeconds()
    return `${year}/${month}/${day} ${hour}:${minutes}:${seconds}`
} 

  const [messageApi, contextHolder] = message.useMessage();
  const success = (string) => {
    messageApi.open({
      type: 'success',
      content: string,
    });
  };
  const error = (string) => {
    messageApi.open({
      type: 'error',
      content: string,
    });
  };

const [form] = Form.useForm();

const showCreateModal = () => {
    setOpenCreareModal(true);
};

const cancelCreateModel = () => {
    setOpenCreareModal(false);
};

const handleCreateModelOK = () => {
    const formvalues = form.getFieldsValue(true)
    console.log(`点击了OK ${formvalues.name}`)
    console.log(`点击了OK ${formvalues.remark}`)

    if (!formvalues.name || formvalues.name.length <= 0) {
        error(`请输入渠道名称`)
        return
    }

    setConfirmLoading(true)

    const query = new AV.Query("M_Channel");
    query.equalTo("name",formvalues.name)

    query.count().then((count) => {

        if (count > 0) {
            console.log(`渠道名称已存在，请重新填写渠道名`);
            error('渠道名称已存在，请重新填写渠道名')
            setConfirmLoading(false)
        } else {
            const channel = new AV.Object("M_Channel");
            channel.set("name", formvalues.name)
            channel.set("remark", formvalues.remark ? formvalues.remark : '')
            channel.save().then(
                (todo) => {
                    console.log(`渠道保存成功。objectId：${todo.id}`);
                    setConfirmLoading(false)
                    cancelCreateModel()
                    success("渠道创建成功")
                    loadChannelDatas()
                },
                (error) => {
                    console.log(`渠道保存失败：${error}`);
                    error(error.message)
                    setConfirmLoading(false)
                }
            )
        }
    }).catch(err => {
        alert(`网络连接错误，${err.toString()}`);
    })
    
};

const resultColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
});

const handleSave = (row) => {
    console.log(`保存渠道`);
    console.log(row);
    const newData = [...channelDatas];
    const index = newData.findIndex((item) => row.objectId === item.objectId);
    const item = newData[index];
    if (row.remark === item.remark) {

    } else {
        newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setChannelDatas(newData);

        const todo = AV.Object.createWithoutData("M_Channel", row.objectId)
        todo.set("remark", row.remark);
        todo.save();

    }
    
};

return (
    <MLayout selectedKey={'Channel'} openKey={'1'}>
        {contextHolder}
        <Breadcrumb
            style={{
                margin: '10px 16px',
                fontWeight: 600,
                color: '#ffffff',
                fontSize: '18px',
            }}
        >
            <Breadcrumb.Item> 渠道管理 </Breadcrumb.Item>
        </Breadcrumb>
        <Divider 
            style={{
                margin: '10px 0',
            }} 
        />
        
        <div 
            className='main'
            style={{

            }}
        >
            <Space style={{ marginBottom: '10px' }}  >
                <Button onClick={(e) => { showCreateModal() }}>添加渠道</Button>
            </Space>
            
            <Table components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={resultColumns}
                    dataSource={channelDatas} />

            <Modal
                title="创建渠道"
                okText="确定"
                cancelText="取消"
                destroyOnClose={true}
                open={openCreareModal}
                onOk={handleCreateModelOK}
                confirmLoading={confirmLoading}
                onCancel={cancelCreateModel}
            >
                <Form
                    preserve={false}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout='horizontal'
                    form={form}
                    initialValues={{
                        layout: 'horizontal',
                    }}
                    style={{
                        marginTop: 30,
                        maxWidth: 700,
                    }}
                >
                    <Form.Item 
                        label="渠道名称"
                        name="name"
                    >
                        <Input placeholder="输入渠道名称" />
                    </Form.Item>
                    <Form.Item 
                        label="备注"
                        name="remark"
                    > 
                        <Input placeholder="输入渠道的备注描述" />
                    </Form.Item>
                    
                </Form>

            </Modal>
            
        </div>

    </MLayout>

    )


}

export default Channel;
