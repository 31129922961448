import React, { useEffect } from 'react';

import './App.css';

import LoginPage from './Pages/Login/login';
import Channel from './Pages/ChannelPage/channel';
import Batch from './Pages/BatchPage/batch';
import Output from './Pages/Output/output';
import BatchData from './Pages/BatchDatas/batchData';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


function App() {


  useEffect(() => {
    console.log('App useEffect')
    
  }, [])
  
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/Channel" element={<Channel />} />
      <Route path="/Batch" element={<Batch />} />
      <Route path="/Output" element={<Output />} />
      <Route path="/BatchData" element={<BatchData />} />
      
    </Routes>
  </BrowserRouter>
    // <Channel />
  );
}

export default App;
